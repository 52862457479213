$src: "../../assets/img";

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #029293 center top / 100% auto no-repeat url("#{$src}/bg.jpg");
}

.popup {
  margin: 40% 20px 20px;
  padding: 36px;
  text-align: center;
  background: var(--color-white);
  display: grid;
  gap: 36px;
  border-radius: 24px;
}

.btnWr {
  display: grid;
  gap: 12px;
  justify-content: center;
}

.btn {
  background: var(--color-turquoise);
  color: var(--color-white);
  font-weight: 700;
  border-radius: 24px;
  line-height: 48px;
  font-size: 16px;
  width: 230px;
  padding-inline: 32px;
}

.main {
  margin-block: 40% 50px;
  margin-inline: 20px;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  gap: 10px;

  a {
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }
}

.txt {
  color: var(--color-white);
  text-align: center;
  max-width: 270px;
  margin: 0 auto 32px;
}

.langSwitcher {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 47px;
  z-index: 1;
}

.language {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  color: transparent;
  width: 47px;
  height: 47px;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border-radius: 24px;

  &.active {
    background-color: rgba(255, 255, 255, 22%);
    box-shadow: inset 0 -3px 2px rgba(0, 0, 0, 33%), inset 0 3px 2px rgba(255, 255, 255, 65%);
  }

  input {
    visibility: hidden;
    margin: 0;
    padding: 0;
    width: 0;
  }

  span {
    width: 37px;
    height: 37px;
    flex-shrink: 0;
  }
}

label.language.active {
  display: none;
}

.activeLang {
  background-color: rgba(255, 255, 255, 22%);
  box-shadow: inset 0 -3px 2px rgba(0, 0, 0, 33%), inset 0 3px 2px rgba(255, 255, 255, 65%);

  span {
    width: 37px;
    height: 37px;
    flex-shrink: 0;
  }
}

.language-en {
  background: center / cover no-repeat url("#{$src}/en.png");
}

.language-ge {
  background: center / cover no-repeat url("#{$src}/ge.png");
}

.langList {
  display: none;

  &.active {
    display: block;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 70%);
  transition: all 0.2s ease-in-out;

  &.active {
    height: 100%;
    width: 100%;
  }
}
