// Montserrat Font Family
@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "IQOS";
  src: url("../assets/fonts/iqos/IQOS-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "IQOS";
  src: url("../assets/fonts/iqos/IQOS-Bold.ttf") format("truetype");
  font-weight: 700;
}
