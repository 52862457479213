@import "./fonts.scss";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap";

:root {
  --color-white: #ffffff;
  --color-soft-white: #fffdfb;
  --color-turquoise: #00d1d2;
  --color-dove-grey: #6f6f6f;
  --font-main: "IQOS", "Montserrat", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: var(--font-main);
  font-size: 14px;
  line-height: 24px;
}

h1 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
}
